#container {
	min-height: 8.5cm;
	width: 5.5cm;
	font-family: Calibri; 
	font-size: 8pt;
	border: 0.06cm solid #000;
	background-color: #FFF;
	padding-bottom: 0.5cm;
}
#idtop {
	width: 5.4cm;
	text-align: center;
	border-bottom: #00b050 solid 0.3cm;
}
#toplines {
	padding-top: 0.1cm;
	font-family: Calibri; 
	font-size: 12pt !important;
	font-weight: 800 !important;
	letter-spacing: 0.2em !important;
	color: #00b050 !important;
}
#sphoto {
	width: 5.4cm;
	height: 2.7cm;
	text-align: center;
	padding-top: 0.1cm;
}
#simg {
	height: 54pt;
	width: auto;
	border: 1px solid #000;
	vertical-align: middle;
  background-color: #FFF;
}
#mimg {
	height: 40pt;
	width: auto;
	border: 1px solid #000;
	vertical-align: middle;
  background-color: #FFF;
  margin: 0.06cm;
}
#names {
	width: 5.4cm;
	height: 0.85cm;
  padding-top: 0.2cm;
}
#sname {
	width: 5.4cm;
	text-align: center;
	font-size: 9pt;
	font-weight: 600;
  color: #000;
}
#mname {
	padding-top: 0.1cm;
	margin-left: 0.3cm;
	float:left;
	line-height: 8pt;
	height:8pt;
	overflow: hidden;
}
#fname {
	padding-top: 0.1cm;
	margin-right: 0.3cm;
	float:right;
	line-height: 8pt;
	height:8pt;
	overflow: hidden;
}
#classes {
	clear: both;
	width: 4.8cm;
	height: 0.34cm;
	margin-left: 0.3cm;
	color: #FFF;
	font-weight: 600;
  font-size: 7.5pt;
	background-color: #00b050;	
}
#classes2 {
	clear: both;
	width: 4.8cm;
	height: 0.34cm;
	margin-left: 0.3cm;
	font-size: 7pt;
  margin-top: 0;
}
#add {
	width: 4.8cm;
	margin-left: 0.3cm;
	margin-top: 0.1cm;
	line-height: 8pt;
	height:8pt;
	overflow: hidden;
}
#add2 {
	width: 4.8cm;
	margin-left: 0.3cm;
	line-height: 8pt;
	height:8pt;
	overflow: hidden;
}
#ph {
	width: 4.8cm;
	margin-top: 0.0cm;
	margin-left: 0.3cm;
	margin-right: 0.3cm;
	font-weight: 600;
	line-height: 8pt;
	height:42pt;
	overflow: hidden;
	padding:0px;
	text-align: center;
}
#valid {
	margin-right: 0.3cm;
	margin-top: 0.32cm;
	text-align: right;
}
.helper {
    display: inline-block;
    height: 100%;
    vertical-align: middle;
}
.bold {
	font-weight: 600;
}
.label {
	float: left;
	width: 1.6cm;
	text-align: center;
}
.labelspic {
	float: left;
	width: 1.6cm;
	text-align: center;
}
.labelmpic {
	float: left;
	width: 2.6cm;
	text-align: center;
}
.labelfpic {
	float: right;
	width: 1.6cm;
	text-align: center;
}