/* Common css utilities v2.3.1*/

/* Margins and Paddings*/
.mt-05 {
  margin-top: 5px;
}
.mt-10 {
  margin-top: 10px;
}
.mt-15 {
  margin-top: 15px;
}
.mt-20 {
  margin-top: 20px;
}
.mt-30 {
  margin-top: 30px;
}
.mt-40 {
  margin-top: 40px;
}
.mt-48 {
  margin-top: 48px;
}
.mt-60 {
  margin-top: 60px;
}

.ml-05 {
  margin-left: 5px;
}
.ml-08 {
  margin-left: 8px;
}
.ml-16 {
  margin-left: 16px;
}
.ml-30 {
  margin-left: 30px;
}

.mb-10 {
  margin-bottom: 10px;
}
.mb-30 {
  margin-bottom: 30px;
}
.mb-48 {
  margin-bottom: 48px;
}
.mb-60 {
  margin-bottom: 60px;
}
.mb-120 {
  margin-bottom: 120px;
}

.pt-05 {
  padding-top: 5px;
  display: block;
}
.pt-08 {
  padding-top: 8px;
}
.pt-16 {
  padding-top: 16px;
}
.pt-24 {
  padding-top: 24px;
}

.pb-08 {
  padding-bottom: 8px;
}
.pb-16 {
  padding-bottom: 16px;
}
.pb-24 {
  padding-bottom: 24px;
}

.pl-01 {
  padding-left: 1px;
}
.pl-08 {
  padding-left: 8px;
}
.pl-16 {
  padding-left: 16px;
}
.pl-24 {
  padding-left: 24px;
}

.pr-08 {
  padding-right: 8px;
}
.pr-16 {
  padding-right: 16px;
}
.pr-24 {
  padding-right: 24px;
}

.hide {
  display: none;
}
/* Texts*/
.h1 {
  font-size: 36px;
  font-weight: 600;
}
.h2 {
  text-transform: capitalize;
  font-size: 18px;
  font-weight: 600;
  color: #000;
}
.h3 {
  font-size: 24px;
  font-weight: 600;
  color: var(--ion-color-dark);
}
.h4 {
  font-size: 16px;
  color: var(--ion-color-medium);
}
.h6 {
  font-size: 9px;
  color: var(--ion-color-medium);
}
.text-black {
  color: #111;
}
.text-dark {
  color: var(--ion-color-dark);
}
.text-light {
  color: var(--ion-color-light);
}
.text-bold {
  font-weight: 600;
}
.text-capitalize {
  text-transform: capitalize;
}
.text-uppercase {
  text-transform: uppercase;
}
.font-color-1 {
  color: var(--ion-color-secondary);
}
.font-color-2 {
  color: var(--ion-color-warning);
}
.font-color-3 {
  color: var(--ion-color-danger);
}
.font-color-4 {
  color: rgb(255, 125, 69);
}
.font-color-5 {
  color: rgb(0, 111, 96);
}
.font-color-6 {
  color: rgb(78, 40, 122);
}
.font-color-7 {
  color: var(--ion-color-success);
}
.font-none {
  text-decoration: none;
}
.font-size-12 {
  font-size: 14px;
}
/* Containers */
.overflow-hide {
  overflow: hidden;
}
.overflow-x {
  overflow-x: auto;
}
.max-xxl {
  max-width: 1600px;
  margin: auto;
}
.max-xl {
  max-width: 1200px;
  margin: auto;
}
.max-lg {
  max-width: 800px;
  margin: auto;
}
.max-md {
  max-width: 550px;
  margin: auto;
}
.min-xxxxxl {
  min-width: 2800px;
}
.min-xxxxl {
  min-width: 2400px;
}
.min-xxxl {
  min-width: 2000px;
}
.min-xxl {
  min-width: 1600px;
}
.min-xl {
  min-width: 1200px;
}
.min-lg {
  min-width: 800px;
}
.min-md {
  min-width: 550px;
}
/* Vertical Align Center */
.vertical-container {
  height: 100%;
}
.vertical-center {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

/* Misc*/
.stick-left {
  background-color: var(--ion-color-light);
  position:sticky;
  z-index: 2;
  left: 0;
}
.center-label {
  width: 100%;
  text-align: center;
}
.pointer {
  cursor: pointer;
}
.button-right {
  text-align: right;
}
.white-button {
  border: 2px solid #FFF;
  color: #FFF;
  font-weight: 600;
  text-transform: uppercase;
}
.status-text {
font-size: 16px;
color: #FFF;
}
.file-text {
  padding-left: 16px;
  font-size: 12px;
}
.border-radius-10 {
  border-radius: 10px;
}
.border-radius-20 {
  border-radius: 20px;
}
.border-top-sec {
  border-top: 1px solid var(--ion-color-medium);
}
.border-bottom-sec {
  /*border-bottom: 1px solid var(--ion-color-medium);*/
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 600;
  color: var(--ion-color-dark);
  padding-left: 8px;
  padding-right: 8px;
}
.border-left {
  border-left: 1px solid var(--ion-color-medium);
}
.border-medium {
  border: 1px solid var(--ion-color-medium);
}
.help-icon {
  position: relative;
  top: 5px;
  margin-left: 8px;
  font-size: 20px;
}
.info-popover {
  --width: 320px;
}
.blur-row::after {
  backdrop-filter: blur(3px);
  width: 100%;
  height: 100%;
  position: absolute; 
  content: "\1F512   Login to unlock";
  text-align: center;
  font-weight: 600;
}
.offset-y-fix {
  --offset-y: -260px;
  --width: 280px;
}
.upload-popup {
  --width: 320px;
}
.settings-menu {
  --width: 240px;
  --offset-y: 10px;
  --offset-x: -30px;
}
.project-progress {
  margin-top: 8px;
  height: 8px;
  width: 120px;
}
.contact-us-home {
  font-size: 18px !important;
}
.display-table
{
  display: flex; /* 2. display flex to the rescue */
  flex-direction: row;
}
.table-select {
  min-height: 18px;
  display: block;
  width: auto;
}
.badge-button {
  position: relative;
}
.badge-icon {
  font-size: 24px;
}
.badge-label {
  background-color: var(--ion-color-danger);
  border-radius: 10px;
  color: #FFF;
  padding: 1px 3px;
  font-size: 11px;
  position: absolute;
  top: 0;
  right: -3px;
}
.no-click {
  pointer-events: none;
}
.invoice-border {
  border: 2px solid rgba(var(--ion-color-primary-rgb), 0.6);
}

/* preview pdf icon container*/
.pdf-preview {
  margin-top:30px;
  margin-bottom:30px;
}

/* Ion Loader */
.first-loading {
  font-size: 14px;
  font-weight: 600;
}

.link-dark {
  color: var(--ion-color-dark);
}

/* Ion Alert */

.first-alert {

}


/* Auth Helpers */
.auth-label {
  color: var(--ion-color-primary) !important;
  font-weight: 600;
}
.auth-input {
  color: var(--ion-color-dark) !important;
}
.auth-title {
  color: var(--ion-color-secondary) !important;
  font-weight: 600;
  font-size: 24px;
  text-transform: uppercase;
}
.auth-link {
  color: var(--ion-color-secondary) !important;
  font-weight: 600;
  font-size: 16px;
  font-family: 'Helvetica', 'Arial', sans-serif;
  text-decoration: none;
}

/* Ion Menu*/
.menu-title {
  text-transform: uppercase;
  font-size: 18px;
  letter-spacing: 1.2px;
}
.menu-title-1 {
  font-size: 18px !important;
}
.menu-note-1 {
  font-size: 16px;
}
.menu-logo {
  width: 40px;
  filter: drop-shadow(3px 3px 3px var(--ion-color-light));
}
.menu-border {
  border-bottom: 2px solid var(--ion-color-dark);
}

/*Router Tabs*/
.tab-button {
  --color-selected: var(--ion-color-secondary);
  --color: var(--ion-color-dark);
}

.tab-bar {
  --background: #c4dae0;
  border-top: 1px solid #999;
}
body.dark .tab-bar {
    --background: #001519;
}

.swap-sheet {
  --background: var(--ion-color-light);
  --color: var(--ion-color-secondary);
  text-transform: capitalize;
}

/* Mobile Users v2.2 */
.vid-title {
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 600;
}
.vid-table-title {
  background-color: var(--ion-color-secondary);
}
.vid-table-heading {
  color: #EEE;
  font-size: 16px;
}
.vid-table-icon {
  display: inline-block;
  vertical-align: middle;
  font-size: 20px;
  color: #EEE;
}
.vid-responsive-field {
  margin-top: 5px;
  margin-left: 36px;
  color: var(--ion-color-secondary);
  font-size: 14px;
}
.vid-responsive-field-red {
  margin-top: 5px;
  margin-left: 36px;
  color: var(--ion-color-danger);
  font-size: 14px;
}
.vid-responsive-field-1 {
  margin-top: 5px;
  color: var(--ion-color-secondary);
  font-size: 14px;
}
.vid-responsive-field-1-red {
  margin-top: 5px;
  color: var(--ion-color-danger);
  font-size: 14px;
}
.vid-responsive-field-2 {
  color: var(--ion-color-tertiary);
  font-size: 14px;
}

/* isActive Segments */
.isactive-segment {
  --background: rgba(var(--ion-color-secondary-rgb), 0.4);
  color: #000;
}
body.dark .isactive-segment {
    --background: rgba(var(--ion-color-secondary-rgb));
    color: #FFF;
}

.ios .active-group .list-title {
  padding: 0; 
  text-align: left;
}

/* Ion Elements with Background Color */
.home-shade {
  --background: rgba(var(--ion-color-secondary-rgb), 0.2);
}
.grey-shade {
    --background: var(--ion-color-light);
    color: #000;
}
.primary-shade {
    --background: var(--ion-color-primary);
}
.secondary-shade {
  --background: var(--ion-color-secondary);
}
.tertiary-shade {
    --background: var(--ion-color-tertiary);
}
.white-shade {
    background: #FFF;
    color: #111;
}
.black-shade {
    background: #000;
    color: #FFF;
}
.receipt-shade {
  background: #FFF;
  color: #111;
}
.item-white-shade {
  --background: #FFF;
}
.white-bg {
  background: #FFF;
}
/* Ion Footer */
.footer-shade {
  background: #FFF;
  color: #111;
  box-shadow: 0px -2px 4px rgba(0, 0, 0, 0.26);
}

.footer-shade-grey {
  background: var(--ion-color-light);
}

.footer-stats {
  font-weight: 600;
  color: var(--ion-color-medium);
  font-size: 12px;
}


body.dark .footer-shade {
  background: #161616;
  color: #FFF;
}
body.dark .white-shade {
  background: #000;
  color: #FFF;
}
body.dark .black-shade {
  background: #FFF;
  color: #000;
}

/*School Logo and Name*/

.home-row {
  border-bottom: 2px solid var(--ion-color-dark);
  padding-bottom: 16px;
}
.preview-text {
  color: var(--ion-color-dark);
  font-size: 22px!important;
}
.logo-text {
  color: var(--ion-color-dark);
  font-size: 20px!important;
}


/* Buttons Styling with clear fill*/

.dash-button {
  font-family: 'Segoe UI', sans-serif;
  color: var(--ion-color-secondary);
  font-weight: 600;
  text-transform: uppercase;
  font-size: 12px;
}
.dash-button-red {
  font-family: 'Segoe UI', sans-serif;
  color: var(--ion-color-danger);
  font-weight: 600;
  text-transform: uppercase;
  font-size: 12px;
}

.first-button {
  border: 2px solid var(--ion-color-secondary);
  color: var(--ion-color-secondary);
  font-weight: 600;
  text-transform: uppercase;
  font-size: 13px;
  min-height: 2.1em;
  --padding-bottom: 4px;
  --padding-end: 8px;
  --padding-start: 8px;
  --padding-top: 4px;
}
.second-button {
  border: 2px solid var(--ion-color-primary);
  color: var(--ion-color-primary);
  font-weight: 600;
  text-transform: uppercase;
  font-size: 13px;
  min-height: 2.1em;
  --padding-bottom: 4px;
  --padding-end: 8px;
  --padding-start: 8px;
  --padding-top: 4px;
}
.third-button {
  font-weight: 600;
  text-transform: uppercase;
  font-size: 13px;
  min-height: 2.1em;
  --padding-bottom: 4px;
  --padding-end: 8px;
  --padding-start: 8px;
  --padding-top: 4px;
}
.forth-button {
  border: 2px solid var(--ion-color-success);
  color: var(--ion-color-success);
  font-weight: 600;
  text-transform: uppercase;
  font-size: 13px;
  min-height: 2.1em;
  --padding-bottom: 4px;
  --padding-end: 8px;
  --padding-start: 8px;
  --padding-top: 4px;
}
.forth-button-small {
  border: 2px solid var(--ion-color-success);
  color: var(--ion-color-success);
  font-weight: 600;
  text-transform: uppercase;
  font-size: 11px;
  min-height: 2.1em;
  --padding-bottom: 4px;
  --padding-end: 8px;
  --padding-start: 8px;
  --padding-top: 4px;
}
/*v2.2*/
.fifth-button {
  border: 2px solid var(--ion-color-danger);
  color: var(--ion-color-danger);
  font-weight: 600;
  text-transform: uppercase;
  font-size: 13px;
  min-height: 2.1em;
  --padding-bottom: 4px;
  --padding-end: 8px;
  --padding-start: 8px;
  --padding-top: 4px;
}

/* Card Text*/
.first-meta {
  color: var(--ion-color-dark);
  font-size: 14px;
}
.second-meta {
color: var(--ion-color-dark);
font-size: 14px;
font-weight: 600;
}

/* Ion Icon Sizes*/
.first-icon {
  font-size: 64px;
}
.second-icon {
  font-size: 42px;
}
.third-icon {
  font-size: 30px;
  color: var(--ion-color-dark);
}
.forth-icon {
  font-size: 18px;
  color: var(--ion-color-dark);
}


/* Alternate Items BG color */
.alt-item {
  --background: rgba(var(--ion-color-secondary-rgb), 0.05);
}
.alt-item-2 {
  --background: rgba(var(--ion-color-primary-rgb), 0.05);
}
.alt-item-3 {
  --background: rgba(var(--ion-color-success-rgb), 0.05);
}
.alt-item-4 {
  --background: rgba(var(--ion-color-danger-rgb), 0.05);
}

/* Form Inputs */
.input-label {
  font-weight: 600;
}
.input-field {
  margin-top: 16px;
}

/* IonLabel Headings */
.title-heading {
  font-size: 18px;
  font-weight: 600;
}

/*admin lists*/
.list-title h3 {
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 600;
  color: var(--ion-color-secondary);

}

.list-label h3 {
  line-height: 22px;
}

.list-label-2 {
  font-size: 18px;
  font-weight: 600;
}

/* Reports */
.reports-icon {
  font-size: 64px;
  color: #EEE;
}

.reports-info {
  font-size: 32px;
  color: #EEE;
}

.reports-label {
  font-size: 22px;
  color: #EEE;
}
/* Chat threads*/

/* Shiny effect for table row */
.shiny-row {
  position: relative;
  background-color: rgba(var(--ion-color-dark-rgb), 0.9) !important;
  border-top: 3px solid var(--ion-color-light);
  color: var(--ion-color-light);
  padding-top: 8px;
  padding-bottom: 8px;
  font-weight: 600;
  overflow: hidden;
}

.shiny-row::before {
  content: '';
  position: absolute;
  top: 0;
  left: -75%; /* Start off-screen */
  width: 30%;
  height: 100%;
  background: linear-gradient(120deg, rgba(var(--ion-color-light-rgb), 0.03), rgba(var(--ion-color-light-rgb), 0.09), rgba(var(--ion-color-light-rgb), 0.03));
  transform: skewX(-45deg); /* Slant the shine effect */
  z-index: 1; /* Above the content */
}

@keyframes shine {
  0% {
    left: -75%;
  }
  100% {
    left: 125%; /* Move across the row */
  }
}

.shiny-row::before {
  animation: shine 3s infinite ease-in-out; /* Animation duration and repeat */
}

/* Ensure table text is above the shine effect */
.shiny-row .table-field {
  position: relative;
  z-index: 2;
}

/* Data Tables*/

.ion-table {

}
.row-table {
  background-color: rgba(var(--ion-color-secondary-rgb), 0.01);
  border-bottom: 1px solid var(--ion-color-medium);
}
.row-table-alt {
  background-color: #ffffff;
  border-bottom: 1px solid var(--ion-color-medium);
}
.row-table-success {
  background-color: rgba(var(--ion-color-success-rgb), 0.05);
  border-bottom: 1px solid var(--ion-color-medium);
}
.row-table-danger {
  background-color: rgba(var(--ion-color-danger-rgb), 0.05);
  border-bottom: 1px solid var(--ion-color-medium);
}
.table-title {
  background-color: var(--ion-color-dark);
}
.table-title-1 {
  background-color: rgba(var(--ion-color-primary-rgb), 0.6);
}
.table-title-2 {
  background-color: rgba(var(--ion-color-warning-rgb), 0.6);
}
.table-heading {
  color: var(--ion-color-light);
  font-size: 16px;
}
.table-heading-1 {
  color: var(--ion-color-dark);
  font-size: 16px;
}
.table-icon {
  display: inline-block;
  vertical-align: middle;
  font-size: 24px;
}
.table-field {
  font-size: 16px;
  margin-top: 10px;
  margin-bottom: 10px;
}
.table-field-no-margin {
  font-size: 16px;
}
.danger-field {
  background: rgba(var(--ion-color-danger-rgb), 0.6);
}
.success-field {
  background: rgba(var(--ion-color-success-rgb), 0.6);
}
.warning-field {
  background: rgba(var(--ion-color-warning-rgb), 0.6);
}
.secondary-field {
  background: rgba(var(--ion-color-secondary-rgb), 0.6);
}
.heading-fixed {
  background-color: var(--ion-color-dark);
  position:sticky;
  z-index: 2;
  left: 0;
}
.field-fixed {
  background-color: #DCE7EC;
  position:sticky;
  z-index: 2;
  left: 0;
}
.field-fixed-alt {
  background-color: #ffffff;
  position:sticky;
  z-index: 2;
  left: 0;
}
.field-fixed-1 {
  background-color: var(--ion-color-light);
  position:sticky;
  z-index: 2;
  left: 0;
}

body.dark .row-table, body.dark .field-fixed {
  background-color: #122222;
}
body.dark .row-table-alt, body.dark .field-fixed-alt {
  background-color: #111111;
} 

/*action buttons & no items cards*/
.profile-photo-card {
  background: linear-gradient(rgba(var(--ion-color-primary-rgb), 0.01), rgba(var(--ion-color-primary-rgb), 0.05));
}
.medical-card {
  background: linear-gradient(rgba(var(--ion-color-danger-rgb), 0.1), rgba(var(--ion-color-danger-rgb), 0.2));
}
.time-table-card {
  height: 64px;
}
.primary-card {
  border-left: 10px solid var(--ion-color-primary);
}
.secondary-card {
  border-left: 10px solid var(--ion-color-secondary);
}
.tertiary-card {
  border-left: 10px solid var(--ion-color-tertiary);
}
.success-card {
  border-left: 10px solid var(--ion-color-success);
}
.warning-card {
  border-left: 10px solid var(--ion-color-warning);
}
.red-card {
  border-left: 10px solid var(--ion-color-danger);
}
.red-card .list-title h3 {
  font-size: 14px;
  color: var(--ion-color-danger);
  line-height: 18px;
}

/* Dashboard Action Buttons */
.action-card {
  --background: linear-gradient(45deg, rgba(var(--ion-color-secondary-rgb), 0), rgba(var(--ion-color-secondary-rgb), 0.01) 30%, rgba(var(--ion-color-secondary-rgb), 0.05) 60%, rgba(var(--ion-color-secondary-rgb), 0));
  background-size: 400% !important;
  box-shadow: rgba(var(--ion-color-dark-rgb), 0.4) 0px 3px 3px 1px;
  -webkit-animation: Gradient 8s ease infinite !important;
  -moz-animation: Gradient 8s ease infinite !important;
  animation: Gradient 8s ease infinite !important;
}
.action-card-2 {
  --background: linear-gradient(-45deg,  rgba(255, 255, 255, 0.7), rgba(255, 255, 255, 0.2) 30%, rgba(255, 255, 255, 0.3) 60%, rgba(255, 255, 255, 0.8));
  box-shadow: rgba(var(--ion-color-danger-rgb), 0.4) 0px 3px 3px 1px;
}
body.dark .action-card-2 {
  --background: linear-gradient(-45deg,  rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.2) 30%, rgba(0, 0, 0, 0.3) 60%, rgba(0, 0, 0, 0.8));
}

@-webkit-keyframes Gradient {
  0%{background-position:0% 50%}
  50%{background-position:100% 50%}
  100%{background-position:0% 50%}
}
@-moz-keyframes Gradient {
  0%{background-position:0% 50%}
  50%{background-position:100% 50%}
  100%{background-position:0% 50%}
}
@keyframes Gradient { 
  0%{background-position:0% 50%}
  50%{background-position:100% 50%}
  100%{background-position:0% 50%}
}

.action-card .action-title{
  text-transform: uppercase;
}
.action-card img {
  width: 45px;
  height: 45px;
}

/*admin buttons title*/
.action-title {
  font-size: 16px;
  font-weight: 600;
  margin-top: 8px;
}

.action-button {
  font-size: 12px;
  font-weight: 600;
}


body.dark .action-card .action-title{
  color: #fff;
}

/*admin pages action cards*/
.action-card-b {
  border-left: 10px solid var(--ion-color-secondary);
  --background: linear-gradient(rgba(var(--ion-color-secondary-rgb), 0.01), rgba(var(--ion-color-secondary-rgb), 0.05));
}

.action-card-b img {
  width: 45px;
  height: 45px;
}

.action-card-c {
  border-left: 10px solid var(--ion-color-primary);
  --background: linear-gradient(rgba(var(--ion-color-primary-rgb), 0.01), rgba(var(--ion-color-primary-rgb), 0.05));
}
.action-card-d {
  border-left: 10px solid var(--ion-color-danger);
  --background: linear-gradient(rgba(var(--ion-color-danger-rgb), 0.01), rgba(var(--ion-color-danger-rgb), 0.05));
}
.action-card-s {
  border-left: 10px solid var(--ion-color-success);
  --background: linear-gradient(rgba(var(--ion-color-success-rgb), 0.01), rgba(var(--ion-color-success-rgb), 0.05));
}
.action-card-w {
  border-left: 10px solid var(--ion-color-warning);
  --background: linear-gradient(rgba(var(--ion-color-warning-rgb), 0.01), rgba(var(--ion-color-warning-rgb), 0.05));
}

.action-link {
  background-color: var(--ion-color-secondary);
}

.card-link {
  color: var(--ion-color-secondary);
}

.action-text {
  color: var(--ion-color-dark);
  font-size: 13px;
  font-weight: 600;
  line-height: 22px;
}
.action-text-2 {
  color: var(--ion-color-dark);
  font-size: 14px;
  font-weight: 600;
  text-transform: capitalize;
}
.action-text-3 {
  color: var(--ion-color-dark);
  font-size: 15px;
  font-weight: 600;
  text-transform: capitalize;
}
.action-text-4 {
  color: var(--ion-color-dark);
  font-size: 14px;
  font-weight: 600;

}

/* Information on Admin Actions */
.info-text {
  font-size: 14px;
  color: var(--ion-color-medium);
}

/* Admin Items*/
.item-transparent {
  --background: transparent;
}
.sub-label {
  color: var(--ion-color-secondary);
  font-weight: 600;
  font-size: 16px;
}
.fee-comp-height {
  height: 48px;
}

/* Kanban v2.2.5 */
.fullscreen-board {
  height: 100%;
}
.categorySlide {
  display: flex;
  flex-direction: column;
  border-right: dashed 1px var(--ion-color-medium);
  height: auto !important;
  justify-content: normal !important;
  margin-bottom: 16px;
}
.categoryName {
  background-color: var(--ion-color-secondary);
  color: white;
  width: 100%;
  padding: 16px;
  font-size: 16px;
  max-height: 50px;
  min-height: 50px;
}
.title-icon-left{
  position: absolute;
  top: 50%;
  left: 0px;
  transform: translateY(-50%);
}
.title-icon-right{
  position: absolute;
  top: 50%;
  right: 0px;
  transform: translateY(-50%);
}
.title-icon-left ion-icon {
  padding: 8px;
}
.title-icon-right ion-icon {
  padding: 8px;
}
.kanbanItem {
  width: 80%;
  margin-top: 16px;
  padding: 16px;
  border: 1px solid var(--ion-color-medium);
  border-radius: 5px;
  background-color: white;
  --background: white;
  text-align: left;
}
.hot {
  border: 2px solid var(--ion-color-danger);
}
.warm {
  border: 2px solid var(--ion-color-warning);
}
.cold {
  border: 2px solid var(--ion-color-secondary);
}
.kanbanItem .itemTitle {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-content: center;
  align-items: center;
  margin-bottom: 6px;
}
.kanbanItem .itemTitle .itemType {
  font-size: 0.75rem;
}
.kanbanItem .itemTitle .itemType ion-icon {
  font-size: 0.75rem;
  padding: 0 !important;
}
.kanbanItem .itemTitle h3 {
  color: black;
  font-weight: 450;
}
.kanbanItem .itemList {
  display: flex !important;
  flex-direction: row !important;
  align-content: center;
  justify-content: space-between;
  align-items: center;
}
.kanbanItem .itemList p {
  color: #111111;
}
.kanbanItem .itemList ion-icon {
  font-size: 2rem;
  padding-left: 0.5rem;
}
.kanbanItem .itemType {
  display: flex;
  flex-direction: row;
  align-content: center;
  align-items: center;
  justify-content: space-between;
  width: fit-content;
}
.kanbanItem .itemLabels {
  margin-top: 1rem;
}
.kanbanItem .itemActions {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-content: center;
  align-items: center;
  margin-top: 1rem;
}
.kanbanItem .itemActions div {
  width: 5rem;
  background-color: #eee;
  color: #ccc;
  margin-left: 0.2rem;
  margin-right: 0.2rem;
  padding: 0.5rem;
  border-radius: 5px;
}
.kanbanItem .itemActions .moveRight {
  text-align: right;
}
.kanbanItem .itemActions .moveLeft {
  text-align: left;
}

.itemAdd-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 70%; /* Take full horizontal space */
  height: 24px;
  margin-bottom: 8px;
  margin-top: 20px;
}

.itemAdd {
  display: flex;
  flex: 0 1 80%; /* Occupy 80% of the width */
  padding: 10px;
  background-color: rgba(var(--ion-color-medium-rgb), 0.2);
  color: var(--ion-color-dark);
  border: 2px dashed var(--ion-color-medium);
  border-radius: 5px;
  font-size: 16px;
}

.itemAdd-secondary {
  display: flex;
  flex: 0 1 20%; /* Occupy 20% of the width */
  padding: 10px;
  font-size: 24px;
}

.result-heading {
  text-transform: uppercase;
  font-weight: 600;
  font-size: 12px;
}

.result-score {
  font-size: 24px;
}

/* Attendance*/
.present-button {
  background-color: rgba(var(--ion-color-success-rgb), 0.8);
  height: 64px;
}
.absent-button {
  background-color: rgba(var(--ion-color-danger-rgb), 0.8);
  height: 64px;
  color: var(--ion-color-light);
}

/* Chat */
.chat-parent-row {
  position: relative;
}
.chat-parent-card {
  margin-top: 6px;
  margin-bottom: 6px;
  border-radius: 10px;
  background-color: rgba(var(--ion-color-primary-rgb), 0.1);
  color: var(--ion-color-dark);
}
.chat-admin-row {
  position: relative;
}
.chat-admin-card {
  margin-top: 6px;
  margin-bottom: 6px;
  border-radius: 10px;
  background-color: rgba(var(--ion-color-secondary-rgb), 0.1);
  color: var(--ion-color-dark);
}
.chat-user-name {
  text-transform: capitalize;
  font-size: 12px;
}
.chat-right-img {
  position: absolute;
  bottom: 0;
  right: 0;
}
.chat-left-img {
  position: absolute;
  bottom: 0;
  left: 0;
}
.chat-left-attach {

}
.chat-right-attach {

}
.chat-left-col {
  padding-top: 24px;
  padding-right: 16px;
  text-align: right;
}
.chat-right-col {
  padding-top: 24px;
  padding-left: 16px;
  text-align: left;
}
.chat-attach-caption {
  font-size: 9px;
  line-height: 12px;
  text-decoration: underline;
  color: var(--ion-color-primary);
}
.chat-timestamp {
  color: var(--ion-color-medium);
  font-size: 12px;
}
.chat-avatar {
    margin-left: auto;
    margin-right: auto;
    width: 40px;
    height: 40px;
}

/* Home Admissions*/
.insti-name {
  font-size: 16px;
  font-weight: 600;
}
.insti-address {
  font-size: 12px;
  color: var(--ion-color-medium) !important;
  padding-left: 16px;
}
.admn-open {
  padding-left: 16px;
  font-size: 14px;
  font-weight: 600;
  color: var(--ion-color-danger);
}

.home-button {
  background: linear-gradient(-45deg, rgba(var(--ion-color-secondary-rgb), 0.6), rgba(var(--ion-color-secondary-rgb), 0.9) 40%, rgba(var(--ion-color-secondary-rgb), 0.6));
  border: 2px solid #FFF;
  color: #FFF;
  font-weight: 600;
  text-transform: uppercase;
  font-size: 13px;
  min-height: 2.1em;
  --padding-bottom: 4px;
  --padding-end: 8px;
  --padding-start: 8px;
  --padding-top: 4px;
}

.home-footer {
  background: #222428;
}

/* School Profile */
.class-card {
  border-left: 6px solid var(--ion-color-secondary);
  border-radius: 10px;
  padding: 8px;
}
.class-name {
  font-weight: 600;
  font-size: 16px;
}
.class-info {
  font-size: 12px;
}
.class-info-2 {
  font-size: 12px;
  font-weight: 600;
  color: var(--ion-color-secondary);
}
.class-info-3 {
  font-size: 14px;
  font-weight: 600;
}
.class-info-4 {
  font-size: 12px;
}
.info-subtitle {
  font-size: 16px;
}
.fac-icon {
  font-size: 18px;
}
.fac-label {
  font-size: 14px !important;
}
.stat-name {
  font-size: 16px;
  font-weight: 600;
  color: var(--ion-color-dark);
}

/* Admissions User */
.note-card {
  --background: rgba(var(--ion-color-primary-rgb), 0.2);
  border: 2px solid var(--ion-color-primary);
  padding: 4px;
}
.note-card-1 {
  --background: rgba(var(--ion-color-secondary-rgb), 0.2);
  border: 2px solid var(--ion-color-secondary);
  padding: 4px;
}
.note-card-2 {
  --background: rgba(var(--ion-color-danger-rgb), 0.2);
  border: 2px solid var(--ion-color-danger);
  padding: 4px;
}
.note-card-3 {
  --background: rgba(var(--ion-color-success-rgb), 0.2);
  border: 2px solid var(--ion-color-success);
  padding: 4px;
}
.note-card-5 {
  --background: rgba(var(--ion-color-medium-rgb), 0.2);
  border: 2px solid var(--ion-color-medium);
  padding: 4px;
}
.note-icon {
  font-size: 24px;
}
.note-text p {
  /*for mobile - font-size: 12px;*/
  font-size: 14px;
  color: var(--ion-color-dark);
  line-height: 18px;
}
.action-card-one {
  border-radius: 10px;
}
.action-title-default h3 {
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
}
.action-title-one h3 {
  font-size: 16px;
  font-weight: 600;
  color: var(--ion-color-secondary);
  line-height: 24px;
}
.action-title-two h3 {
  font-size: 16px;
  font-weight: 600;
  color: var(--ion-color-tertiary);
  line-height: 24px;
}
.child-card {
  border-radius: 10px;
}
.child-card-selected {
  border-radius: 10px;
  border: 1px solid var(--ion-color-secondary);
}
.child-name {
  font-size: 16px;
  font-weight: 600;
  color: var(--ion-color-secondary);
  line-height: 36px;
  padding-left: 8px;
}
.child-label p {
  font-size: 16px;
}

/* Dashboard v2.2.5 */

.dashboard-card {
  font-family: 'Segoe UI', sans-serif;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
}
.dash-border-bottom:after {
  content: "";
  display: block;
  width: 95%;
  margin: 1em auto 0;
  border-bottom: 2px solid rgba(var(--ion-color-medium-rgb), 0.5);
}

.dash-card {
  font-family: 'Segoe UI', sans-serif;
  margin: 16px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
  width: 100%;
}

.dash-title {
  font-size: 20px;
  font-weight: 600;
  text-align: left;
  margin-top: 20px;
}
.dash-icon {
  background: var(--ion-color-light);
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}
.dash-box-info {
  font-size: 12px;
  text-align: left;
}

.dash-box {
  margin: 0;
  padding: 0;
  border: 2px solid var(--ion-color-dark);
  border-radius: 5px;
  text-align: center;
}
.dash-box-header {
  background: var(--ion-color-dark);
  min-height: 44px;
}
.dash-box-title {
  font-size: 11px;
  text-align: center;
  color: var(--ion-color-light);
}
.dash-box-footer {
  margin-top: 8px;
  margin-bottom: 8px;
}
.dash-box-stat {
  font-size: 14px;
  font-weight: 600;
  color: var(--ion-color-dark);
}


.dash-label {
  font-size: 15px;
  padding-left: 16px;
  line-height: 24px;
  color: #FFF;
  text-align: left;
}

.dash-label-2 {
  font-size: 15px;
  padding-left: 16px;
  line-height: 24px;
  color: var(--ion-color-dark);
  text-align: left;
}

.dash-info {
  color: #FFF;
  font-size: 15px;
  padding-right: 16px;
  line-height: 24px;
}

.dash-info-2 {
  color: var(--ion-color-dark);
  font-size: 15px;
  padding-right: 16px;
  line-height: 24px;
}

.dash-bg-1 {
  background: linear-gradient( rgba(var(--ion-color-warning-rgb), 0.1), rgba(var(--ion-color-warning-rgb), 0.2) 40%, rgba(var(--ion-color-warning-rgb), 0.3)); 
}
.dash-bg-2 {
  background: linear-gradient(rgba(var(--ion-color-secondary-rgb), 0.1), rgba(var(--ion-color-secondary-rgb), 0.2) 40%, rgba(var(--ion-color-secondary-rgb), 0.3));
}
.dash-bg-3 {
  background: linear-gradient(rgba(var(--ion-color-danger-rgb), 0.1), rgba(var(--ion-color-danger-rgb), 0.2) 40%, rgba(var(--ion-color-danger-rgb), 0.3));
}
.dash-bg-4 {
  background: linear-gradient( rgba(255, 125, 69, 0.1), rgba(255, 125, 69, 0.2) 40%, rgba(255, 125, 69, 0.3));
}
.dash-bg-5 {
  background: linear-gradient( rgba(78, 40, 122, 0.1), rgba(78, 40, 122, 0.2) 40%, rgba(78, 40, 122, 0.3));
}
.dash-bg-6 {
  background: linear-gradient(rgba(0, 111, 96, 0.1), rgba(0, 111, 96, 0.2) 40%, rgba(0, 111, 96, 0.3));
}


/* Mobile Dashboard Buttons */

.dash-title-1 {
  color: #FFF;
  font-size: 12px;
  font-weight: 600;
  margin-top: 8px;
  font-family: 'Segoe UI', sans-serif;
  height: 36px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.dash-card-1 {
  border-radius: 10px;
  background: linear-gradient(-45deg, rgba(255, 125, 69, 0.3), rgba(255, 125, 69, 0.9) 40%, rgba(255, 125, 69, 0.3));
  height: 140px;
  display: flex;
}
.dash-card-2 {
  border-radius: 10px;
  background: linear-gradient(-45deg, rgba(78, 40, 122, 0.3), rgba(78, 40, 122, 0.9) 40%, rgba(78, 40, 122, 0.3));
  height: 140px;
  display: flex;
}
.dash-card-3 {
  border-radius: 10px;
  background: linear-gradient(-45deg, rgba(var(--ion-color-danger-rgb), 0.3), rgba(var(--ion-color-danger-rgb), 0.9) 40%, rgba(var(--ion-color-danger-rgb), 0.3));
  height: 140px;
  display: flex;
}
.dash-card-4 {
  border-radius: 10px;
  background: linear-gradient(-45deg, rgba(0, 111, 96, 0.3), rgba(0, 111, 96, 0.9) 40%, rgba(0, 111, 96, 0.3));
  height: 140px;
  display: flex;
}
.dash-card-5 {
  border-radius: 10px;
  background: linear-gradient(-45deg, rgba(var(--ion-color-secondary-rgb), 0.3), rgba(var(--ion-color-secondary-rgb), 0.9) 40%, rgba(var(--ion-color-secondary-rgb), 0.3));
  height: 140px;
  display: flex;
}
.dash-card-6 {
  border-radius: 10px;
  background: linear-gradient(-45deg, rgba(var(--ion-color-primary-rgb), 0.3), rgba(var(--ion-color-primary-rgb), 0.9) 40%, rgba(var(--ion-color-primary-rgb), 0.3));
  height: 140px;
  display: flex;
}
.dash-card-7 {
  border-radius: 10px;
  background: linear-gradient(-45deg, rgba(var(--ion-color-success-rgb), 0.3), rgba(var(--ion-color-success-rgb), 0.9) 40%, rgba(var(--ion-color-success-rgb), 0.3));
  height: 140px;
  display: flex;
}
.dash-card-8 {
  border-radius: 10px;
  background: linear-gradient(-45deg, rgba(var(--ion-color-warning-rgb), 0.3), rgba(var(--ion-color-warning-rgb), 0.9) 40%, rgba(var(--ion-color-warning-rgb), 0.3));
  height: 140px;
  display: flex;
}
.dash-card-9 {
  border-radius: 10px;
  background: linear-gradient(-45deg, rgba(var(--ion-color-tertiary-rgb), 0.3), rgba(var(--ion-color-tertiary-rgb), 0.9) 40%, rgba(var(--ion-color-tertiary-rgb), 0.3));
  height: 140px;
  display: flex;
}

/*Common Form*/

.checkout-amt {
  font-size: 16px;
  font-weight: 600;
  padding-top: 4px;
  margin: 0;
}

.checkout-label {
  font-size: 12px;
  margin: 0;
  color: var(--ion-color-medium);
}


/* STUDENT Stats*/

.overview-icon {
  color:#FFF;
  font-size: 24px;
}
.stat-card-1 {
  border-radius: 15px;
  --background: linear-gradient(-45deg, rgba(var(--ion-color-secondary-rgb), 0.6), rgba(var(--ion-color-secondary-rgb), 0.9) 40%, rgba(var(--ion-color-secondary-rgb), 0.6));
}
.stat-card-2 {
  border-radius: 15px;
  --background: linear-gradient(-45deg, rgba(var(--ion-color-danger-rgb), 0.6), rgba(var(--ion-color-danger-rgb), 0.9) 40%, rgba(var(--ion-color-danger-rgb), 0.6));
}
.stat-card-3 {
  border-radius: 15px;
  --background: linear-gradient(-45deg, rgba(var(--ion-color-warning-rgb), 0.6), rgba(var(--ion-color-warning-rgb), 0.9) 40%, rgba(var(--ion-color-warning-rgb), 0.6));
}
.stat-card-4 {
  border-radius: 15px;
  --background: linear-gradient(-45deg, rgba(var(--ion-color-success-rgb), 0.6), rgba(var(--ion-color-success-rgb), 0.9) 40%, rgba(var(--ion-color-success-rgb), 0.6));
}
.stat-card-5 {
  border-radius: 15px;
  --background: linear-gradient(-45deg, rgba(255, 125, 69, 0.6), rgba(255, 125, 69, 0.9) 40%, rgba(255, 125, 69, 0.6));
}
.stat-card-6 {
  border-radius: 15px;
  background: linear-gradient(-45deg, rgba(78, 40, 122, 0.6), rgba(78, 40, 122, 0.9) 40%, rgba(78, 40, 122, 0.6));
}
.stat-card-7 {
  border-radius: 15px;
  --background: linear-gradient(-45deg, rgba(0, 111, 96, 0.6), rgba(0, 111, 96, 0.9) 40%, rgba(0, 111, 96, 0.6));
}
.stat-subhead {
  color:#FFF;
}
.stat-head {
  color:#FFF;
  font-size: 14px;
  font-weight: 600;
}
.stat-icon {
  color:#FFF;
  font-size: 20px;
  margin-top: 10px;
}
.stat-subhead-1 {
  color:#000;
}
.stat-head-1 {
  color:#000;
  font-size: 14px;
  font-weight: 600;
}
.stat-icon-1 {
  color:#000;
  font-size: 20px;
  margin-top: 10px;
}
.stat-button {
  border: 2px solid #fefefe;
  color: #fefefe;
  font-weight: 600;
  text-transform: uppercase;
  font-size: 11px;
}

/* Planner Admin */
.eventDay {
  border: 2px solid var(--ion-color-secondary) !important;
}

/*User Profile*/

.profile-card {
  border-radius: 10px;
  border-left: 10px solid var(--ion-color-secondary);
  --background: linear-gradient(rgba(var(--ion-color-secondary-rgb), 0.1), rgba(var(--ion-color-secondary-rgb), 0.2));
}
.profile-name {
  color: var(--ion-color-secondary);
  font-size: 16px;
  font-weight: 600;
  line-height: 16px;
  text-transform: capitalize;
}
.profile-email {
  font-size: 14px;
  font-weight: 600;
  line-height: 14px;
}


/* SwapCards*/
.parent-home-title{
  font-size: 16px;
  text-transform: capitalize;
  padding-top: 2px;
  color: #FFF;
}
.action-text-5 {
  color: #111;
  font-size: 16px;
  font-weight: 600;
  text-transform: capitalize;
}
.action-icon {
  font-size: 36px;
}


/* Tran Cards */
.tran-icon {
  font-size: 22px;
}
.tran-icon-2 {
  font-size: 18px;
}
.tran-rem {
  color: var(--ion-color-dark);
  font-size: 13px;
  line-height: 22px;
}
.tran-tag {
  display: inline-block;
  margin-left: 5px;
  margin-top: 10px;
  color: var(--ion-color-dark);
  text-transform: uppercase;
  font-size: 11px;
  font-weight: 600;
  border-radius: 2px;
  border: 2px solid var(--ion-color-secondary);
  padding: 5px;
}
.tran-tag-two {
  display: inline-block;
  margin-left: 5px;
  margin-top: 10px;
  color: var(--ion-color-dark);
  text-transform: uppercase;
  font-size: 11px;
  font-weight: 600;
  border-radius: 2px;
  border: 2px solid var(--ion-color-dark);
  padding: 5px;
}
.tran-text {
  color: var(--ion-color-dark);
  font-size: 13px;
  line-height: 22px;
}
.tran-pad {
  padding-top: 8px;
  padding-bottom: 8px;
  padding-left: 16px;
  padding-right: 8px;
}
.tran-mode {
  color: var(--ion-color-dark);
  font-size: 11px;
  line-height: 14px;
}
.tran-label {
  color: var(--ion-color-secondary);
  font-size: 11px;
  line-height: 14px;
}
.tran-label-1 {
  color: var(--ion-color-danger);
  font-size: 11px;
  line-height: 14px;
}
.tran-row {
  background: rgba(var(--ion-color-secondary-rgb), 0.2);
}
.tran-row-1 {
  background: rgba(var(--ion-color-danger-rgb), 0.2);
}
.class-row {
  background: rgba(var(--ion-color-primary-rgb), 0.2);
}
.table-row {
  background: rgba(var(--ion-color-secondary-rgb), 0.2);
}
.title-row {
  background: var(--ion-color-secondary);
  color: #FFF;
}

/* Receipt */

.rec-logo {
  border-bottom: 1px solid #333;
}
.rec-insti {
  color: #111;
  font-size: 20px!important;
}

.logo-address {
  color: #111;
  font-size: 14px!important;
}

.rec-title {
  color: #111;
  font-size: 14px;
  line-height: 22px;
  font-weight: 600;
}
.rec-text {
  color: #111;
  font-size: 16px;
  line-height: 22px;
}
.rec-label {
  color: var(--ion-color-secondary);
  font-size: 11px;
  line-height: 14px;
}
.rec-label-1 {
  color: #111;
  font-size: 11px;
  line-height: 14px;
  font-weight: 600;
}
.rec-label-2 {
  color: var(--ion-color-danger);
  font-size: 11px;
  line-height: 14px;
  font-weight: 600;
}
.rec-row {
  padding-bottom: 40px;
  border-bottom: 2px solid #bcbcbc;
}
.rec-foot {
  color: #111;
  font-size: 12px;
  line-height: 16px;
}
.rec-paid {
  color: #111;
  text-transform: capitalize;
  font-size: 12px;
  font-weight: 600;
  line-height: 14px;
}

.receipt-row {
  border-bottom: 1px solid #333;
}
.receipt-col {
  padding: 8px;
}

/* Financial Reports */
.reports-in h1 {
  color: var(--ion-color-success);
  font-weight: 600;
  font-size: 24px;
}
.reports-out h1 {
  color: var(--ion-color-danger);
  font-weight: 600;
  font-size: 24px;
}
.reports-warn h1 {
  color: var(--ion-color-warning);
  font-weight: 600;
  font-size: 24px;
}
.reports-attend h1 {
  color: var(--ion-color-dark);
  font-size: 14px;
  line-height: 24px;
}
.attend-badge {
  font-size: 20px;
  right: 0;
  position:absolute;
}


/* Student Profile */
.profile-text {
  text-transform: capitalize;
  font-size: 16px;
  font-weight: 600;
  color: var(--ion-color-secondary);
}
.profile-text-1 {
  font-size: 16px;
  font-weight: 600;
  color: var(--ion-color-success);
}

/* Student Profile Reminder */
.overdue h1 {
  font-size: 32px;
  color: var(--ion-color-danger);
}

/* Parent Cards*/

.danger-card {
  border-left: 10px solid var(--ion-color-danger);
}

.border-top {
  border-top: 1px solid #999;
}

.student-overdue h1 {
  margin-left: 10px;
  color: var(--ion-color-danger);

}


/* Notifications or Announcements List */
.note-date {
  font-size: 12px;
  color: #666;
}
.note-title {
  font-weight: 600;
  font-size: 14px;
  color: var(--ion-color-secondary);
}
.note-text {
  user-select: auto;
  font-size: 14px;
}

/* Parent Home In Out Status*/
.active-danger {
    border-left: 10px solid var(--ion-color-danger);
   
}
.active-success {
  border-left: 10px solid var(--ion-color-success);
 
}
.active-secondary {
  border-left: 10px solid var(--ion-color-secondary);

}
.active-primary {
  border-left: 10px solid var(--ion-color-primary);

}

.recent-label {
  font-weight: 600;
  color: #333;
  font-size: 11px;
}
.recent-label h3 {
  font-weight: 600;
  font-size: 14px;
  text-transform: capitalize;
}

/*Parent Home Recent Update
Attendance Log Calendar */
.present {
  border: 2px solid var(--ion-color-success) !important;
}
.absent {
  border: 2px solid var(--ion-color-danger) !important;
}
.half {
  border: 2px solid var(--ion-color-warning) !important;
}
.late {
  border: 2px solid var(--ion-color-tertiary) !important;
}
.leave {
  border: 2px solid var(--ion-color-secondary) !important;
}
.holiday {
  border: 2px solid var(--ion-color-medium) !important;
}
.selected-day {
  border: none !important;
  color: #000 !important;
  background: #FFF !important;
}
.box {
  display: flex;
  align-items:center;
}
.present-info {
  background: var(--ion-color-success);
  display: inline-block;
  width: 20px;
  height: 20px;
  margin: 8px;
}
.absent-info {
  background: var(--ion-color-danger);
  display: inline-block;
  width: 20px;
  height: 20px;
  margin: 8px;
}
.late-info {
  background: var(--ion-color-tertiary);
  display: inline-block;
  width: 20px;
  height: 20px;
  margin: 8px;
}
.half-info {
  background: var(--ion-color-warning);
  display: inline-block;
  width: 20px;
  height: 20px;
  margin: 8px;
}
.leave-info {
  background: var(--ion-color-secondary);
  display: inline-block;
  width: 20px;
  height: 20px;
  margin: 8px;
}
.holiday-info {
  background: var(--ion-color-medium);
  display: inline-block;
  width: 20px;
  height: 20px;
  margin: 8px;
}

/* Subjects */

.subject-card {
  border-radius: 15px;
  --background: linear-gradient(-45deg, rgba(var(--ion-color-primary-rgb), 0.6), rgba(var(--ion-color-primary-rgb), 0.9) 40%, rgba(var(--ion-color-primary-rgb), 0.6));
  margin-bottom: 20px;
  border: 2px solid var(--ion-color-dark);
}
.subject-card-1 {
  border-radius: 15px;
  --background: linear-gradient(-45deg, rgba(var(--ion-color-secondary-rgb), 0.6), rgba(var(--ion-color-secondary-rgb), 0.9) 40%, rgba(var(--ion-color-secondary-rgb), 0.6));
  margin-bottom: 20px;
  border: 2px solid var(--ion-color-dark);
}

.subject-icon {
  color:#000;
  font-size: 30px;
  margin-top: 15px;
}
.subject-head {
  color:#000;
  font-size: 18px;
  
}

.subject-icon-1 {
  color:#FFF;
  font-size: 30px;
  margin-top: 15px;
}
.subject-head-1 {
  color:#FFF;
  font-size: 18px;

}
.subject-card-4 {
  border-radius: 10px;
  border: 2px solid var(--ion-color-primary);
}
.subject-desc-4 {
  margin-top: 10px;
  margin-bottom: 10px;
  font-size: 16px;
}

.subject-icon-4 {
  color: var(--ion-color-primary);
  font-size: 30px;
}
.subject-head-4 {
  color: var(--ion-color-dark);
  font-size: 18px;
  font-weight: 600;
}

.course-desc {
  display: block;
  padding: 16px;
  font-size: 18px;
}

.chapter-card {
  margin-bottom: 20px;

}

.chapter-icon {
  color: var(--ion-color-dark);
  font-size: 30px;
}
.chapter-head {
  color: var(--ion-color-dark);
  font-size: 18px;
}
.chapter-progress {
  margin-top: 8px;
  height: 8px;
}

.topic-item {
  margin-top: 10px;
}
.topic-label h3 {
  font-size: 14px;
  font-weight: 600;
}
.topic-label p {
  margin-top: 10px;
  font-size: 12px;
  
}
.topic-icon {
  font-size: 24px;
}


/* Planner */
.planner-segment {
  --background: #FFF;
  font-weight: 600;
}

.planner-button {
  --background: var(--ion-color-light);
  --color: var(--ion-color-dark);
  --indicator-color: var(--ion-color-warning);
  --background-checked: var(--ion-color-warning);
  --color-checked: #000;
}


.planner-timeline h1 {
  position: absolute;
  top: 10%;
  right: 10%;
  color: var(--ion-color-medium);
  font-size: 14px;
  font-weight: 600;
  
}
.planner-timeline h2 {
  position: absolute;
  bottom: 10%;
  right: 10%;
  color: var(--ion-color-medium);
  font-size: 14px;
  font-weight: 600;
}

.planner-card {
  border-left: 6px solid var(--ion-color-secondary);
  border-radius: 10px;
}

.planner-card-2 {
  border-left: 6px solid var(--ion-color-primary);
  border-radius: 10px;
}

.planner-card-3 {
  border-left: 6px solid var(--ion-color-tertiary);
  border-radius: 10px;
}

.planner-col h3 {
  font-size: 9px;
  color: var(--ion-color-medium);

}

.planner-col p {
  font-size: 16px;
  font-weight: 600;
  color: var(--ion-color-dark);

}


/*Transaction*/

.transact-label h3 {
  font-size: 16px;
  color: var(--ion-color-dark);
  margin-top: 16px;
}
.transact-label p {
  font-size: 11px;
  color: var(--ion-color-medium);
}

.transact-amt h3 {
  font-size: 15px;
  font-weight: 600;
  margin-top: 16px;
}
.transact-amt p {
  font-size: 11px;
}

/*Circulars*/

.circular-timeline {
  color: var(--ion-color-medium);
  font-size: 14px;
  font-weight: 600;
  
}
.full-width {
  width: 260px !important;
}
.circular-row {
  border-left: 6px solid var(--ion-color-primary);
  background: linear-gradient(rgba(var(--ion-color-primary-rgb), 0.01), rgba(var(--ion-color-primary-rgb), 0.05));
}
.announce-row {
  border-left: 6px solid var(--ion-color-secondary);
  background: linear-gradient(rgba(var(--ion-color-secondary-rgb), 0.01), rgba(var(--ion-color-secondary-rgb), 0.05));
}

.circular-card {
  border-left: 6px solid var(--ion-color-primary);
  background: linear-gradient(rgba(var(--ion-color-primary-rgb), 0.01), rgba(var(--ion-color-primary-rgb), 0.05));
}
.circular-netflix {
  border-left: 4px solid var(--ion-color-primary);
  background: #FFF;
}
.announce-netflix {
  border-left: 4px solid var(--ion-color-secondary);
  background: #FFF;
}
body.dark .circular-netflix, body.dark .announce-netflix {
  background: #000;
}
.netflix-col h3 {
  font-size: 12px;
  color: var(--ion-color-medium);
}

.netflix-col p {
  white-space:nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 16px;
  font-weight: 600;
  color: var(--ion-color-dark);
}
.announce-card {
  border-left: 6px solid var(--ion-color-secondary);
  background: linear-gradient(rgba(var(--ion-color-secondary-rgb), 0.01), rgba(var(--ion-color-secondary-rgb), 0.05));
}

.ongoing-card {
  border-left: 6px solid var(--ion-color-warning);
  background: linear-gradient(rgba(var(--ion-color-warning-rgb), 0.01), rgba(var(--ion-color-warning-rgb), 0.05));
}

.circular-badge {
  margin-top: 8px;
  color: var(--ion-color-dark);
  --background: rgba(var(--ion-color-primary-rgb), 0.2);
  --padding-bottom: 6px;
  --padding-end: 12px;
  --padding-start: 12px;
  --padding-top: 6px;
}

.announce-badge {
  margin-top: 8px;
  color: var(--ion-color-dark);
  --background: rgba(var(--ion-color-secondary-rgb), 0.2);
  --padding-bottom: 6px;
  --padding-end: 12px;
  --padding-start: 12px;
  --padding-top: 6px;
}

.circular-icon {
  font-size: 24px;
}

.announcement-card {
  min-height: 420px;
  user-select: auto !important;
  white-space: pre-line !important;
}

.anchorme-text {
  user-select: auto !important;
  white-space: pre-line !important;
}

/* Leaderboard */

.leaderboard-row {
  background: linear-gradient(rgba(var(--ion-color-secondary-rgb), 0.3), rgba(var(--ion-color-secondary-rgb), 0.1));
  height: 380px;
  border-bottom-left-radius: 50% 20%;
  border-bottom-right-radius: 50% 20%;
  overflow: hidden;
}
.leaderboard-second {
  margin-left:8px;
  margin-right:8px;
  background: linear-gradient( transparent 58%, rgba(var(--ion-color-secondary-rgb), 0.45) 42%);
}
.leaderboard-second .leader-container {
  text-align: center;
  margin-top: 140px;
}
.leaderboard-second img {
  border: 2px solid rgba(var(--ion-color-secondary-rgb), 0.2);
}
.leaderboard-first {
  margin-left:8px;
  margin-right:8px;
  background: linear-gradient( transparent 50%, rgba(var(--ion-color-secondary-rgb), 0.6) 50%);
}
.leaderboard-first .leader-container {
  text-align: center;
  margin-top: 50px;
  margin-left: auto;
  margin-right: auto;
}
.leaderboard-first .leader-img {
  border: 2px solid rgba(var(--ion-color-secondary-rgb), 0.2);
}
.leaderboard-third  {
  margin-left:8px;
  margin-right:8px;
  background: linear-gradient( transparent 64%, rgba(var(--ion-color-secondary-rgb), 0.3) 36%);
}
.leaderboard-third .leader-container  {
  width: 100%;
  margin-top: 165px;
  text-align: center;
}
.leaderboard-third img {
  border: 2px solid rgba(var(--ion-color-secondary-rgb), 0.2);
}
.leader-avatar {
  margin-left: auto;
  margin-right: auto;
}
.numberOneCircle {
  margin-top: 30px;
  margin-bottom: 15px;
  margin-left: auto;
  margin-right: auto;
  border-radius: 50%;
  width: 36px;
  height: 36px;
  padding: 8px;
  background: #f1f1f1;
  border: 2px solid rgba(var(--ion-color-secondary-rgb), 0.5);
  color: rgba(var(--ion-color-secondary-rgb), 0.8);
  font-size: 16px;
  font-weight: 600;
}
.numberCircle {
  margin-top: 20px;
  margin-bottom: 10px;
  margin-left: auto;
  margin-right: auto;
  border-radius: 50%;
  width: 36px;
  height: 36px;
  padding: 8px;
  background: #f1f1f1;
  border: 2px solid rgba(var(--ion-color-secondary-rgb), 0.5);
  color: rgba(var(--ion-color-secondary-rgb), 0.8);
  font-size: 16px;
  font-weight: 600;
  
}
.leader-name {
  text-transform: capitalize;
  font-size: 16px;
  font-weight: 600;
  color: #f1f1f1;
}
.first-name {
  text-transform: capitalize;
  font-size: 18px;
  font-weight: 600;
  color: #f1f1f1;
}
.leaderboard-item {
  padding-top: 8px;
  padding-bottom: 8px;
}
.leaderboard-rank {
  margin-left: 8px;
  font-size: 18px;
  font-weight: 600;
}
.leaderboard-avatar {
  margin-left: 24px;
}
.leaderboard-name {
  margin-left: 24px;
  text-transform: capitalize;
  margin-right: 0;

}
.leaderboard-name h3 {
  font-size: 18px;
}
.leaderboard-score {
  margin-right: 8px;
  text-align: right;
  margin-left: 0;
  font-size: 18px;
  font-weight: 600;
}

/* Trip History */

.timeline-icon {
  font-size: 20px;
  margin-top: 10px;
}
.timeline-para {
  color: #111;
  font-weight: 600;
  font-size: 14px;
}
.timeline-date {
  color: #111;
  font-size: 12px;
}

.completed-trip-text {
  color: var(--ion-color-dark);
  font-size: 13px;
  font-weight: 600;
  line-height: 22px;
}

.completed-trip-icon {
  font-size: 36px;
}

.action-card-drop {
  border-radius: 10px;
  border-left: 10px solid var(--ion-color-secondary);
  
}
.action-card-pick {
  border-radius: 10px;
  border-left: 10px solid var(--ion-color-primary);
  
}
.trip-detail-icon {
  font-size: 26px;
}

/* Active Route Driver*/
.active-group {
    border: 2px solid var(--ion-color-secondary);
}
.active-pickup {
  border: 2px solid var(--ion-color-primary);
}
.active-drop {
  border: 2px solid var(--ion-color-secondary);
}

.driver-route-text {
  color: var(--ion-color-dark);
  font-size: 16px;
  line-height: 32px;
  font-weight: 600;

}

.blinking {
    height: 15px;
    width: 15px;
    margin-right: 15px;
    background-color: #ff0000;
    border-radius: 50%;
    animation: 1s blink ease infinite;
}

@keyframes blink {
  from, to {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
}

/* Google Maps js Api */
.map-container {
  height: 62vh;
}

.map-container-small {
  height: 52vh;
}

.bottom-right {
border: 2px solid #333;
border-radius: 25px;
position: absolute;
bottom: 20px;
right: 10px;
background-color: #aeaeae;
color: #333;
}

/* maps loading */
.load-body{
    padding: 0;
    margin: 0;
    background-color: #f3f3f3;
    text-align: center;
    height:100%;
    font-family: 'Segoe UI', sans-serif;
    font-weight: 100;
  }
  .load-wrapper{
    position:absolute;
    top:40%;
    left:50%;
    transform:translate(-50%, -50%); 
  }
.load-title {
  padding-top: 30%;
  font-size: 22px;
  color: var(--ion-color-secondary);

}

.circle{
  display: inline-block;
  width: 15px;
  height: 15px;
  background-color: #f3f3f3;
  border-radius: 50%;
  animation: loading 1.5s cubic-bezier(.8, .5, .2, 1.4) infinite;
  transform-origin: bottom center;
  position: relative;
}
@keyframes loading{
  0%{
    transform: translateY(0px);
    background-color: #ddd;
  }
  50%{
    transform: translateY(50px);
    background-color: var(--ion-color-secondary);
  }
  100%{
    transform: translateY(0px);
    background-color: #ddd;
  }
}
.circle-1{
  animation-delay: 0.1s;
}
.circle-2{
  animation-delay: 0.2s;
}
.circle-3{
  animation-delay: 0.3s;
}
.circle-4{
  animation-delay: 0.4s;
}
.circle-5{
  animation-delay: 0.5s;
}
.circle-6{
  animation-delay: 0.6s;
}
.circle-7{
  animation-delay: 0.7s;
}
.circle-8{
  animation-delay: 0.8s;
}

/* Blinking Icons*/
.blinker {

  animation: opacity 2s ease-in-out infinite;
  opacity: 1;
}

@keyframes opacity {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0.25
  }

  100% {
    opacity: 1;
  }

}

/* Dark Theme fix for calender api*/
.custom-calendar .Calendar__yearSelectorText, .custom-calendar .Calendar__monthSelectorItemText, .custom-calendar .Calendar__monthText, .custom-calendar .Calendar__yearText {
  color: #111111;
}

/*Barcode Scanner Ionic Fix*/
.scanner-active {
  --ion-background-color: transparent !important;
}
.scanner-active ion-content {
  --background: transparent !important;
}

/*Swiper Styles*/
.swiper-container {
  width: 100%;
  height: 100%;
}
.netflix-slider .swiper-wrapper {
  padding: 20px 0;
}
.netflix-slider .swiper-slide {
  -webkit-transition: 250ms all;
  transition: 250ms all;
}
.netflix-slider .swiper-slide:hover {
  -webkit-transform: scale(1.2);
          transform: scale(1.2);
  z-index: 1;
}
.netflix-slider .swiper-slide:first-child:hover {
  margin: 0 40px;
}
.netflix-slider .swiper-slide:last-child:hover {
  margin: 0 -40px;
}
.fullscreen-board .swiper-button-prev {
  position: fixed;
  top: 50%;
  transform: translate(0, -50%);
}
.fullscreen-board .swiper-button-next {
  position: fixed;
  top: 50%;
  transform: translate(0, -50%);
}